import Modaal from "modaal";
/**
 * サンプルクラス
 * @constructor
 */

class ModaalSetting {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    $(function() {

      $('.slideModalImage').modaal({
        type: 'image',
      });

      $('.videoModal').modaal({
        type: 'video',
      });

      let scrollPosition = 0;
      $('.onlinestoreModal').modaal({
        type: 'inline',
        custom_class: 'modaal-inline-custom', // ← カスタムクラス名を指定
        disable_scroll: false, 
        before_open: function () {
          // 現在のスクロール位置を記録
          scrollPosition = $(window).scrollTop();
      
          // bodyを固定（スクロール禁止）
          $('body').css({
            position: 'fixed',
            top: `-${scrollPosition}px`,
            width: '100%',
          });
        },
        after_close: function () {
          // body固定を解除
          $('body').css({
            position: '',
            top: '',
            width: '',
          });
      
          // スクロール位置を復元
          $(window).scrollTop(scrollPosition);
        }
      });
      
    });
    
  }
};

export default ModaalSetting;
